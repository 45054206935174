.floatingedges {
    flex-direction: row;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
  .floatingedges .react-flow__handle {
    opacity: 0;
    position: absolute;
    z-index: 5;
  }

   .react-flow__node-resizableNodeSelected {
    font-size: 10svw;
    width: 300px;
    height: 225px;
    background: #f5f5f6;
    color: #222;
    display: grid;
    justify-content: center;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 2px;
    z-index: 5;
  }
  
  .react-flow__node-resizableObjective {
    font-size: 1svw;
    width: 300px;
    height: 225px;
    background: #e5b80B;
    color: #222;
    display: grid;
    justify-content: center;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 20px;
  }

  .react-flow__node-resizableAttackVector {
    font-size: 3svw;
    width: 300px;
    height: 225px;
    background: #ff0000;
    color: #222;
    display: grid;
    justify-content: center;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 40px;
  }

  .react-flow__node-outline {
    font-size: 1svw;
    width: 300px;
    height: 225px;
    background-color: 'orange';
    opacity: '0.3';
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  .react-flow__node-resizableNodeSelected input{
    font-size: 1cap;
  }

  /* .react-flow__node-group{
    font-size: 1cap;
    position: relative;
    z-index: -1;
  } */

  .react-flow__node-resizableNodeSelected .react-flow__handle {
    /* top: 24px; */
    /* right: -15px; */
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
  }

  .react-flow__node-orgate .react-flow__handle {
    /* top: 24px; */
    /* right: -15px; */
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
    position: absolute;
    z-index: 6;
  }

  .react-flow__node-andgate .react-flow__handle {
    /* top: 24px; */
    /* right: -15px; */
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
    position: absolute;
    z-index: 6;
  }
  
  .react-flow__node.circle {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background: '#2B6CB0';
    text-align: center;
  }

  .react-flow__node.orgate {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background: '#2B6CB0';
    text-align: center;
    position: absolute;
    z-index: 5;
  }

  .orGateSVG {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 5;
  }
  
  .outline {
    background-color: 'orange';
    opacity: '0.3';
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  /* .resizableNodeSelected {
    width: 200px;
    height: 320px;
  } */
  
  .react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
  }
  
  .react-flow__node.annotation .react-flow__handle {
    display: none;
  }
  
  .custom-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .custom-node__body {
    padding: 10px;
  }
  
  .custom-node__select {
    position: relative;
    margin-bottom: 10px;
  }
  
  .custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
  }

  .edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
  }
  
  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }

  .context-menu {
    background: white;
    border-style: solid;
    box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
    position: absolute;
    z-index: 10;
  }
  
  .context-menu button {
    border: none;
    display: block;
    padding: 0.5em;
    text-align: left;
    width: 100%;
  }
  
  .context-menu button:hover {
    background: white;
  }
  
  #diamond {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: red;
    position: relative;
    top: -50px;
  }
  #diamond:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: red;
  }
  
  aside {
    border: 1px solid #eee;
    padding: 1rem 1rem;
    /* background: #fcfcfc; */

  }
  
  aside .description {
    margin-bottom: 10px;
  }

  reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }

