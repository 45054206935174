@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Rubik:wght@400;500;700&display=swap');

html, 
body,
#root,
.app {
  height: 100%;
  width: 100%;
  /* font-family: "Rubik", sans-serif; */
  font-family: "Inter", sans-serif;
}

/* .loginPage {
  background: 'linear-gradient(to right, rgb(0, 0, 0), #010d55)';
  color: 'rgb( 255, 255, 255)';
} */



/* CSS from NodeJS Server and JWT with refresh Token project */

/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap'); */

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/* html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    color: #fff;
} */
/* 
body {
    min-height: 100vh;
}  */

.leaflet-container{
    width: 600px;
    height: 600px;
}

/* GREEN GRADIENT BACKGROUND */
/* .bg-container{
    background-color: #fff;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(60deg, hsl(121, 75%, 13%), #0a2a0e, hwb(87 11% 67%));
    background-size: 600% 600%;
    animation: bgAnimatedGradient 15s infinite linear;
}

.introTitle{
    text-align: center;
    padding-top: 25%;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 6px;
    font-size: 4vw;
    color: white;
}

@keyframes bgAnimatedGradient{
    0%{
        background-position: 0 85%;
    }
    50%{
        background-position: 100% 20%;
    }
    100%{
        background-position: 0% 85%;
    }
} */

/* .App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
}  */

/* section {
    width: 100%;
    max-width: 420px;
    min-width: 325px;
    padding: 1rem;
    background-color: rgba(0,0,0,0.4);
} */

form {
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1rem;
}

a, a:visited {
    color: #0519f1;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="organization"],
input[type="message"],
button,
select,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

/* label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
} */

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}
/* 
.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
} */


.persistCheck {
    font-size: 0.75rem;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.persistCheck label {
    margin: 0;
}

[type="checkbox"] {
    height: 20px;
    width: 20px;
    margin: 0 5px 2px 2px;
}

/* QUESTIONNAIRE CSS  */

.score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin-bottom: 20px;
  }
  
  .question-count span {
    font-size: 28px;
  }
  
  .question-text {
    margin-bottom: 12px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
  }
   */
  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }

  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 300px;
  }
  
  
  /* .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
   
  }
   */